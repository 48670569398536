import React from 'react'
import Appheaderpage from '../components/Layout/Appheaderpage'
import Appfooter from '../components/Layout/Appfooter'




function Appdocentes() {
  return (
    <div>
      <Appheaderpage/>
      <main id="main">
      <section id="breadcrumbs" className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Corpo docente</h2>
          <ol>
            <li><a style={{color:'#AB9458'}} href="/">Home</a></li>
            <li>Corpo Docente</li>
          </ol>
        </div>
      </div>
    </section>
    {/* End Breadcrumbs */}
    {/* ======= Portfolio Details Section ======= */}
    <section id="portfolio-details" className="portfolio-details">
      <div className="container">
        <div className="row gy-4">
          <div className="">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Conheça Nosso Corpo Docente</h2>
              <p> Expertise e Excelência na Formação Médica</p>
            </div>
            <div className="row content" data-aos="fade-up">
              <div className=" pt-4 pt-lg-0">
                <p>
                No nosso Instituto de Medicina, o ensino de qualidade começa com professores excepcionais. Contamos com um corpo docente altamente qualificado, composto por médicos, pesquisadores e especialistas renomados, que combinam experiência clínica com dedicação ao ensino. Cada um dos nossos docentes é apaixonado por formar novos profissionais de saúde, oferecendo um aprendizado profundo, atualizado e alinhado às melhores práticas médicas.
                </p>
                <div className="">
                <h2>Formando Profissionais de Saúde com Ensino de Ponta e Expertise Reconhecida</h2>
                <p>
                Com uma abordagem prática e focada no desenvolvimento integral dos alunos, nossos professores incentivam a curiosidade, a inovação e o compromisso com a excelência no cuidado ao paciente. Ao escolher o nosso instituto, você estará se preparando para uma carreira de sucesso com o suporte de quem entende o verdadeiro significado de ser médico.
                </p>
                <br/>
                <section id="team" className="team">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 d-flex align-items-stretch">
                      <div className="member" data-aos="fade-up">
                        <div className="member-img">
                          <img src="assets/img/team-1.jpg" className="img-fluid" alt='' />
                        </div>
                        <div className="member-info">
                          <h4>Marisa Prazeres</h4>
                          <p><i className="ri-check-double-line" style={{color:'#AB9458'}}/> A Subdiretora Administrativa, cuja paixão por ensinar e cuidar dos estudantes transparece em toda a instituição. <br /><br /> <i className="ri-check-double-line" style={{color:'#AB9458'}}/> Com uma carreira sólida na pediatria e extensa experiência em sua carreira proficional, ela proporciona aos alunos uma visão abrangente das condições de saúde, combinando sua expertise clínica com um olhar humano e acolhedor. <br /><br /><i className="ri-check-double-line" style={{color:'#AB9458'}}/> Sua dedicação à formação de profissionais de saúde empáticos e bem preparados é reconhecida por todos os seus alunos, que a veem como exemplo de competência e sensibilidade na medicina pediátrica.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex align-items-stretch">
                      <div className="member" data-aos="fade-up" data-aos-delay={100}>
                        <div className="member-img">
                          <img src="assets/img/team-2.jpg" className="img-fluid" alt='' />
                        </div>
                        <div className="member-info">
                          <h4>Professor Nicolau Sobrinho</h4>
                          <p><i className="ri-check-double-line" style={{color:'#AB9458'}}/> Com uma carreira de destaque o Professor Nicolau Sobrinho é um profissional admirada tanto pela sua habilidade técnica quanto pelo seu compromisso com a saúde da mulher.<br /><br /><i className="ri-check-double-line" style={{color:'#AB9458'}}/> Ele traz para as salas de aula sua vasta experiência em atendimento humanizado, ensinando aos alunos não apenas os aspectos clínicos das doenças ginecológicas e obstétricas, mas também a importância do cuidado emocional e ético no atendimento às pacientes.<br /><br /><i className="ri-check-double-line" style={{color:'#AB9458'}}/> O Professor Nicolau Sobrinho é conhecida por sua abordagem pedagógica envolvente e pela capacidade de inspirar seus alunos a se tornarem médicos conscientes e empáticos</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex align-items-stretch">
                      <div className="member" data-aos="fade-up" data-aos-delay={200}>
                        <div className="member-img">
                          <img src="assets/img/team-3.jpg" className="img-fluid" alt='' />
                        </div>
                        <div className="member-info">
                          <h4>Professora Amélia</h4>
                          <p><i className="ri-check-double-line" style={{color:'#AB9458'}}/> Com muitos anos de experiência no ensino acadêmic, a professora Amélia tem a sua abordagem didática e paciente, aliada a uma vasta bagagem em procedimentos complexos, proporciona aos alunos um aprendizado prático e teórico de alto nível.<br /><br /><i className="ri-check-double-line" style={{color:'#AB9458'}}/> Seu compromisso com a formação médica vai além da sala de aula: ela é um mentora, sempre incentivando a curiosidade e a pesquisa. A dedicação à educação e à ética profissional são marcas do seu trabalho, preparando futuros médicos para os desafios da medicina contemporânea.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex align-items-stretch">
                      <div className="member" data-aos="fade-up" data-aos-delay={300}>
                        <div className="member-img">
                          <img src="assets/img/team-4.jpg" className="img-fluid" alt='' />
                        </div>
                        <div className="member-info">
                          <h4>Professor Dionísio Francisco</h4>
                          <p><i className="ri-check-double-line" style={{color:'#AB9458'}}/>O Professor Dionísio Francisco com uma carreira marcada por importantes contribuições para a área e pelo ensino de excelência. Sua habilidade em transmitir conceitos complexos de forma clara e acessível é um dos maiores diferenciais. <br /><br /><i className="ri-check-double-line" style={{color:'#AB9458'}}/> Além disso, ele promove a integração entre teoria e prática, permitindo que os alunos adquiram um entendimento profundo das doenças cardiovasculares e suas abordagens terapêuticas. Seu comprometimento com a formação de médicos humanistas, atentos ao cuidado integral do paciente, é admirado por alunos e colegas.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               </section>
              </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </section>{/* End Portfolio Details Section */}
    </main>
    <Appfooter />
    </div>
  )
}

export default Appdocentes