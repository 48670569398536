import React from 'react'
import Appheaderpage from '../components/Layout/Appheaderpage'
import Appfooter from '../components/Layout/Appfooter'


function Appdestaques() {
  return (
    <div>
         <Appheaderpage/>
         <main id="main">
      <section id="breadcrumbs" className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Destaques</h2>
          <ol>
            <li><a style={{color:'#AB9458'}} href="/">Home</a></li>
            <li>Destaques</li>
          </ol>
        </div>
      </div>
    </section>
    {/* End Breadcrumbs */}
    {/* ======= Portfolio Details Section ======= */}
    <section id="portfolio-details" className="portfolio-details">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-8">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Melhores Destaques</h2>
              <p>Nossos Destaques Autênticos</p>
            </div>
            <div className="row content" data-aos="fade-up">
              <div className="col-lg-6">
                <p>
                Bem-vindo à nossa Tabela de Preços! Aqui, você encontrará todas as informações detalhadas sobre os custos associados aos nossos serviços e produtos educacionais. A tabela está organizada para oferecer uma visão clara e acessível dos valores referentes a matrículas, mensalidades, materiais didáticos e outras taxas. <br />
                </p>
                <div className="col-lg-12">
                <div className="portfolio-description">
                <div style={{borderRadius:'22px'}} className="portfolio-info">
                <h2>Aula prática de Enfermagem</h2>
                <div className="member-img">
                        <img style={{borderRadius:'20px'}}  src="assets/img/prem03.jpg" className="img-fluid" alt='Prémio Empreendedor' />
                </div>
                <br/>
                <strong>Destaques</strong>
                <p>Data: 13/12/2024 </p>
                <p>
                Aula prática de Enfermagem com o Professor Monteiro Moisés com os estudantes da 12ª classe do curso de enfermagem. Breve explicação sobre as vias de administrações de medicamentos<br />
                </p>
                <p>
                Nas aulas práticas de enfermagem, os alunos vivenciam uma série de experiências que são fundamentais para a construção de sua formação técnica e humanística. Essas aulas não se limitam a ensinar apenas técnicas e procedimentos, mas também a proporcionar um contato mais profundo com o universo do cuidado, a ética profissional e o trabalho em equipe.
                <br/>
                </p>
                <p>
                Elas permitem que os alunos desenvolvam habilidades técnicas, emocionais e sociais, fundamentais para o exercício da profissão. A experiência prática ajuda a formar profissionais mais preparados, confiantes e empáticos, aptos a lidar com a complexidade do cuidado de saúde.
                </p>
                </div>
                </div>
                </div>
                <div className="col-lg-12">
                <div className="portfolio-description">
                <div style={{borderRadius:'22px'}} className="portfolio-info">
                <h2>Dia da África</h2>
                <div className="member-img">
                        <img style={{borderRadius:'20px'}}  src="assets/img/Activi_feira03.jpg" className="img-fluid" alt='analises clinicas' />
                </div>
                <br/>
                <strong>Destaques </strong>
                <p>Data: 25/05/2019</p>
                <p>
                No dia 20 de maio de 2019, nossa instituição realizou uma vibrante celebração do Dia da África, uma data importante para reconhecer e promover a rica diversidade cultural e histórica do continente africano. <br /><br />O evento teve como objetivo não apenas homenagear a contribuição significativa da África para o mundo, mas também proporcionar um ambiente de aprendizado e reflexão sobre temas relevantes relacionados ao continente.<br />
                </p>
                <br/>
                </div>
                </div>
                </div>
                <div className="col-lg-12">
                <div className="portfolio-description">
                <div style={{borderRadius:'22px'}} className="portfolio-info">
                <h2>Entrega de Certificado de participação</h2>
                <div className="member-img">
                        <img style={{borderRadius:'20px'}}  src="assets/img/Dia_Arica04.jpg" className="img-fluid" alt='analises clinicas' />
                </div>
                <br/>
                <strong>Destaques</strong>
                <p>Data: 12/07/2020 </p>
                <p>
                O evento foi um sucesso, com participação ativa dos estudantes e da comunidade escolar. As receitas saudáveis preparadas pelos alunos foram muito elogiadas, e o público teve a chance de aprender mais sobre escolhas alimentares conscientes e a importância de uma alimentação equilibrada para o bem-estar.
                </p>
                <p>
                Essa feira também teve um foco em sustentabilidade, com a promoção do consumo consciente de alimentos e a conscientização sobre o desperdício alimentar.
                </p>
                <p>
                Esse tipo de evento é uma ótima maneira de aproximar teoria e prática, enquanto sensibiliza a comunidade sobre a importância de cuidar da saúde por meio da alimentação.
                </p>
                <br/>
                <br/>
                </div>
                </div>
                </div>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0">
                <div className="portfolio-description">
                <p>
                Estamos felizes em apresentar nossos cursos de Ensino Médio com preços fixos que oferecem excelente custo-benefício. Veja por que nossa instituição é a melhor escolha para você:  
                </p>
                <br/>
                <br/>
                <br/>
                <div style={{borderRadius:'22px'}} className="portfolio-info">
                <h2>Finalistas do Ano Lectivo 2023/24</h2>
                <div className="member-img">
                      <img style={{borderRadius:'20px'}}  src="assets/img/Activi_ortog.jpg" className="img-fluid" alt='Enfermagem' />
                </div>
                <br/>
                <strong>Notícias </strong>
                <p>Ano Lectivo 2023/24</p>
                <p>
                Os alunos que concluíram seus cursos em 2023 em um instituto de saúde estão mais preparados para lidar com os novos desafios do setor, com uma visão mais ampla e integrada da saúde, que inclui não apenas os aspectos clínicos, mas também o apoio psicológico, a tecnologia e a saúde pública.<br />
                </p>
                <p>
                Esses futuros profissionais terão um papel crucial na reconstrução e no fortalecimento dos sistemas de saúde, especialmente à medida que a sociedade enfrenta novas crises de saúde, como doenças crônicas, saúde mental e emergências sanitárias globais.
                </p>
                <br/>
                <br/>
                </div>
                <div style={{borderRadius:'22px',marginTop:'22px'}} className="portfolio-info">
                <h2>2ª Feira Educacional </h2>
                <div className="member-img">
                      <img style={{borderRadius:'20px'}}  src="assets/img/Activi_feira01.jpg" className="img-fluid" alt='Enfermagem' />
                </div>
                <br/>
                <strong>Notícias </strong>
                <p>Data: 11/06/2019 </p>
                <p>
                A 2ª Feira e Educacional Bendizer foi realizados em vinte e cinco de Outubro de dois mil e vinte sobe o lema: Promoção da Saúde como pilares para o desenvolvimento humano, foram realizadas varias actividades como:
                </p>
                <p>
                Efetuar medidas de Glicenia; Pressão Arterial; Peso e Estatura Orietar Dieta Saúdavel na idade Pré-Escolar e Escolar; Orientar as medidas de prevenção de doenças efecçõezas, de Transmição sexual
                </p>
                <p>
                Nesta feira, vimos projetos inovadores, pesquisas inspiradoras e apresentações que mostram o talento e o trabalho duro de nossos alunos. Cada um dos certificados que serão entregues hoje representa mais do que uma conquista acadêmica – é um reflexo do esforço e da dedicação.
                </p>
                <br />
                </div>
                <div style={{borderRadius:'22px',marginTop:'32px'}} className="portfolio-info">
                <h2>Feira da alimentação</h2>
                <div className="member-img">
                      <img style={{borderRadius:'20px'}}  src="assets/img/Activi_feira02.jpg" className="img-fluid" alt='Enfermagem' />
                </div>
                <br/>
                <strong>Notícias </strong>
                <p>Data: 26/04/2020</p>
                <p>
                E a principal temática foi a promoção de hábitos alimentares saudáveis e a educação nutricional. Durante o encontro, professores, apresentaram palestras interativas abordando a importância de uma alimentação equilibrada para a prevenção de doenças crônicas, como diabetes, hipertensão e obesidade
                </p>
                <p>
                O evento incluiu atividades práticas, como oficinas de preparo de receitas saudáveis, onde os participantes aprenderam a criar pratos nutritivos com ingredientes acessíveis.
                </p>
                <p>
                O objetivo principal do evento foi não apenas educar, mas também inspirar os participantes a adotarem práticas alimentares mais saudáveis em sua rotina, além de promover a interação entre profissionais de saúde e a comunidade.
                <p>
                    <br />
                </p>
                </p>
                <br/>
                <br/>
                </div>
              </div>
              </div>
            </div>
          </div>
          </div>
          <div className="col-lg-4">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br/>
            <br />
            <div className="portfolio-description">
            <div style={{borderRadius:'22px'}} className="portfolio-info">
            <h2>Estudantes Qualificados</h2>
            <div className="member-img">
                <img style={{borderRadius:'20px'}}  src="assets/img/Ativi_Desport02.png" className="img-fluid" alt='analises clinicas' />
            </div>
            <br/>
                <strong>Eventos</strong>
                <p>Data: 10/08/2019 </p>
                <p>
                Os estudantes qualificados de nossa instituição são indivíduos comprometidos com a excelência acadêmica e o desenvolvimento pessoal. <br /><br />Eles se destacam por sua curiosidade intelectual, capacidade de pensamento crítico e habilidade de trabalhar em equipe. Com uma mentalidade proativa, buscam constantemente novos desafios e oportunidades de aprendizado.<br />
                </p>
                <p>
                Nossos alunos demonstram responsabilidade social e ética, participando ativamente de projetos comunitários e iniciativas que promovem a inclusão e a sustentabilidade. <br/><br/>
                </p>
            <br/>
            </div>
            </div>
            <div className="portfolio-description">
            <div style={{borderRadius:'22px'}} className="portfolio-info">
            <h2>Atividade Cultural</h2>
            <div className="member-img">
                    <img style={{borderRadius:'20px'}}  src="assets/img/Dia_Arica06.png" className="img-fluid" alt='analises clinicas' />
            </div>
            <br/>
                <strong>Eventos</strong>
                <p>Data: 05/04/2019 </p>
            <p>
            A abertura da atividade foi marcada por uma apresentação especial no auditório da escola. A diretora, Sra. Mariana Costa, fez um discurso caloroso destacando a importância da cultura e das artes na formação dos alunos.<br /><br /> Em seguida, os alunos da turma de Teatro apresentaram uma peça inspirada em histórias folclóricas locais, que encantou a plateia com sua energia e talento.
            </p>
            <p>
            Ao longo do dia, diversos estandes foram montados no pátio da escola, cada um representando uma expressão cultural diferente. Havia uma área dedicada à música, onde os alunos mostraram suas habilidades em diversos instrumentos.
            </p>
            <br/>
            </div>
            </div>
            <div className="portfolio-description">
            <div style={{borderRadius:'22px'}} className="portfolio-info">
            <h2>Actividade da lavagem das mãos</h2>
            <div className="member-img">
                    <img style={{borderRadius:'20px'}}  src="assets/img/esc03.jpg" className="img-fluid" alt='Actividade' />
            </div>
            <br/>
                <strong>Eventos </strong>
                <p>Data: 11/03/2024</p>
            <p>
            Na escola de saúde, a atividade de lavagem das mãos é frequentemente realizada em forma de treinamentos práticos, oficinas ou campanhas educativas. O objetivo principal dessas atividades é sensibilizar os alunos para a importância da higiene das mãos e garantir que eles adquiram a habilidade de realizar essa prática de forma correta e eficaz.
            </p>
            <h4>Recomendações</h4>
            <p>
            Durante uma dessas atividades, os alunos são orientados sobre os momentos críticos em que a lavagem das mãos deve ser realizada: antes e após o atendimento ao paciente, antes de realizar procedimentos invasivos, após o contato com fluidos corporais ou superfícies contaminadas e depois de manusear equipamentos médicos..
            </p>
            <h4>Responsábilidade</h4>
            <p>
            a realização de atividades sobre a lavagem das mãos em uma escola de saúde vai além do simples aprendizado de uma técnica. 
            <br />
            Ao enfatizar a lavagem das mãos desde o início da formação, as escolas de saúde desempenham um papel crucial na promoção da saúde pública e na redução das infecções nosocomiais.
            </p>
            <br/>
            <br/>
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>{/* End Portfolio Details Section */}
    </main>
    <Appfooter/>
    </div>
  )
}

export default Appdestaques